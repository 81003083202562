a, abbr, acronym, address, applet, article, aside, audio, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
  margin: 0; }

ol, ul {
  list-style: none; }

img {
  -ms-interpolation-mode: bicubic;
  height: auto;
  vertical-align: bottom; }

a {
  text-decoration: none; }

a:hover {
  color: #cc3434; }

a:focus {
  outline: 0; }

blockquote, q {
  quotes: none; }

blockquote:after, blockquote:before, q:after, q:before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select, textarea {
  font-family: inherit;
  vertical-align: middle; }

input[type=submit] {
  cursor: pointer; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="checkbox"],
input[type="search"],
textarea,
button,
select {
  outline: none; }

input[type="button"],
input[type="submit"] {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

:placeholder-shown {
  color: #ccc; }

::-webkit-input-placeholder {
  color: #ccc; }

:-moz-placeholder {
  color: #ccc;
  opacity: 1; }

::-moz-placeholder {
  color: #ccc;
  opacity: 1; }

:-ms-input-placeholder {
  color: #ccc; }
